import './Dashboard.css';
import { Table } from 'antd';

function Dashboard({ dashboards }) {
  const commonColumns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Invitation Created Date',
      dataIndex: 'invCreatedDate',
      key: 'invCreatedDate',
    },
  ];

  const getNoDashboardsMessage = (type) => <div>No {type} dashboards.</div>;

  const getTable = (type) => {
    let columns =
      type === 'active'
        ? commonColumns.concat({
            title: 'Invitation Accepted Date',
            dataIndex: 'invAcceptedDate',
            key: 'invAcceptedDate',
          })
        : commonColumns;

    const paginationParams = dashboards[type].length > 10 ? { position: 'bottom' } : false;

    return (
      <Table
        pagination={paginationParams}
        rowClassName={(row, index) => (index % 2 === 0 ? 'dashboards-tableRow-odd' : 'dashboards-tableRow-even')}
        bordered={false}
        dataSource={dashboards[type]}
        columns={columns}
        key={'id'}
      />
    );
  };

  return (
    <>
      <div className="dashboards-title">Insigts</div>
      <div className="dashboards-container">
        <div className="dashboards-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui.
        </div>
        <div className="dashboards-section-title">Active dashboards ({dashboards.active.length})</div>
        {dashboards.active.length ? getTable('active') : getNoDashboardsMessage('active')}
        <div className="dashboards-section-title">Pending dashboards ({dashboards.pending.length})</div>
        {dashboards.pending.length ? getTable('pending') : getNoDashboardsMessage('pending')}
        {/* <div className="dashboards-section-title">Expired dashboards ({dashboards.expired.length})</div>
        {dashboards.expired.length ? getTable('expired') : getNoDashboardsMessage('expired')} */}
      </div>
    </>
  );
}

export default Dashboard;
