import { useState, useEffect } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, Menu } from 'antd';

import './Main.css';
import Dashboard from '../Dashboard/Dashboard';
import DemoInvite from '../DemoInvite/DemoInvite';
import Metrics from '../Metrics/Metrics';
import Invitations from '../Invitations/Invitations';
import CreateDemo from '../CreateDemo/CreateDemo';
import {getRequestHeaders, logout} from "../services/auth.service";
import axios from "../services/request";

function Main() {
  const history = useHistory();
  const [tab, selectTab] = useState('dashboard');
  const [visible, setVisible] = useState(false);
  const [invitations, setInvitations] = useState({
    active: [],
    pending: [],
    // expired: [],
  });
  const [metrics, setMetrics] = useState({
    mostPopularAction: '-//-',
    totalInvitesOpenedCount: '-//-',
    totalInvitesSentCount: '-//-',
  });

  const exitApplication = () => {
      logout();
      history.push('/');
  }

  const formatInvitation = (invitation) => ({
    firstName: invitation.firstName,
    lastName: invitation.lastName,
    company: 'WeVestr',
    email: invitation.email,
    invCreatedDate: moment(invitation.createdAt).format('DD MMM YYYY'),
    invAcceptedDate: moment(invitation.acceptedAt).format('DD MMM YYYY'),
  });
  useEffect(() => {
    axios.get(`/api/statistics`, {headers: getRequestHeaders()})
      .then(
        ({data}) => {
          const {
            data: { invitations: dataInvitations, mostPopularAction, totalInvitesOpenedCount, totalInvitesSentCount },
          } = data;
          const invitations = {};
          invitations.active = dataInvitations.filter((el) => el.status === 'accepted').map(formatInvitation);
          invitations.pending = dataInvitations.filter((el) => el.status === 'pending').map(formatInvitation);
          const metrics = { mostPopularAction, totalInvitesOpenedCount, totalInvitesSentCount };
          setInvitations(invitations);
          setMetrics(metrics);
        },
        (error) => {
            console.log(error);
          setInvitations({active: [], pending: [],});
        },
      );
  }, []);

  return (
    <div className="app">
      <div className="app-headerPanel">
        <img
          src="./assets/wevestr-logo-blue.png"
          srcSet="./assets/wevestr-logo-blue@2x.png 2x, ./assets/wevestr-logo-blue@3x.png 3x"
          className="company-logo"
          alt="company-logo"
        />
        <p className="app-header">Admin Panel</p>
        <Button className="app-openModalButton" onClick={() => setVisible(true)}>
          Create Demo Dashboard
        </Button>
        <Button className="app-closeModalButton" onClick={() => exitApplication()}>Log out</Button>
      </div>
      {visible && <CreateDemo visible={visible} setVisible={setVisible} />}
      <div className="app-container">
        <Menu onClick={(e) => selectTab(e.key)} selectedKeys={[tab]} mode="horizontal">
          <Menu.Item className="app-menuItem" key="dashboard">
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item className="app-menuItem" key="invitations">
            <Link to="/dashboard/invitations">Invitations</Link>
          </Menu.Item>
          <Menu.Item className="app-menuItem" key="analytics">
            <Link to="/dashboard/analytics">Analytics</Link>
          </Menu.Item>
        </Menu>
        <Metrics metrics={metrics} />
        <div>
          <Route exact path="/" render={(props) => <Dashboard {...props} dashboards={invitations} />} />
          <Route path="/dashboard" render={(props) => <Dashboard {...props} dashboards={invitations} />} />
          <Route path="/invitations" component={Invitations} />
          <Route path="/demoinvite" component={DemoInvite} />
        </div>
      </div>
    </div>
  );
}

export default Main;
