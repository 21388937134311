import './DemoInvite.css';
import { useState } from 'react';
import axios from '../services/request';

const Envs = {
  PROD: 'prod',
  LOCAL: 'local',
};

function DemoInvite() {
  const urlsObj = {
    [Envs.PROD]: { title: Envs.PROD, value: 'https://demo.wevestr.com' },
    [Envs.LOCAL]: { title: Envs.LOCAL, value: 'http://localhost:' },
  };
  const [state, setState] = useState({
    selectedEnv: Envs.PROD,
    link: '',
    port: '',
    error: '',
  });

  const getRequestUrl = () => {
    return `${urlsObj[state.selectedEnv].value}${state.port}/api/demo/invitation`;
  };

  const selectUrl = (event) => {
    switch (event.target.value) {
      case Envs.PROD:
        setState({
          ...state,
          selectedEnv: Envs.PROD,
          port: '',
        });
        break;
      case Envs.LOCAL:
        setState({
          ...state,
          selectedEnv: Envs.LOCAL,
          port: '4040',
        });
        break;
      default:
        setState({
          ...state,
          selectedEnv: Envs.PROD,
          port: '',
        });
        break;
    }
  };

  const changePort = (event) => {
    setState({
      ...state,
      port: event.target.value,
    });
  };

  const isLocal = () => state.selectedEnv === Envs.LOCAL;

  const getLink = () => {
    setState({
      ...state,
      error: '',
      link: '',
    });
    const data = process.env.REACT_APP_REQUEST_PARAMS;

    const options = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    };

    axios.post(getRequestUrl(), data, options).then(
      (response) => {
        setState({
          ...state,
          link: response.data.data.link,
        });
      },
      (error) => {
        setState({
          ...state,
          error: error.response.data.message,
        });
      },
    );
  };

  const copyLink = () => {
    const textarea = document.createElement('textarea');
    textarea.style.height = '0';
    document.body.appendChild(textarea);
    textarea.value = state.link;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  return (
    <div>
      <div>
        <button onClick={getLink}>{state.link ? 'Get a new invite' : 'Get invite'}</button>
        <select className="marginL" onChange={selectUrl} defaultValue={state.selectedEnv}>
          {Object.values(urlsObj).map((url) => (
            <option key={url.title} value={url.title}>
              {url.title}
            </option>
          ))}
        </select>
        {isLocal() && (
          <div className="marginL">
            port: <input value={state.port} onChange={changePort}></input>
          </div>
        )}
      </div>
      {state.link && (
        <div>
          <a target="_blank" rel="noreferrer" href={state.link}>
            {state.link}
          </a>
          <button className="marginL" onClick={copyLink}>
            Copy link
          </button>
        </div>
      )}
      {state.error && <div>Error: {state.error}</div>}
    </div>
  );
}

export default DemoInvite;
