import {Button, Form, Input} from "antd";
import {useHistory, useLocation} from "react-router-dom";
import axios from "../services/request";
import {setToken} from "../services/auth.service";
import './Activate.css';

function Activate() {
    const location = useLocation();
    const history = useHistory();
    const onFinish = (values) => {
        values.invitationCode = new URLSearchParams(location.search).get('invitationCode');
        axios.patch(`api/user/`, values).then(response => {
            setToken(response.data.data.tokens.access);
            history.push('/dashboard');
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <section>
            <Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                style={{ marginTop: "100px" }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <p className="activateWelcome">Welcome to WE.VESTR admin panel! Setup your password please.</p>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        { required: true, message: 'Please enter a password!' },
                        () => ({
                            validator(_, value) {
                                if (!value || value.length >= 8) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Password should be at least 8 symbols long'));
                            },
                        })
                        ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="Repeat Password"
                    name="passwordRepeat"
                    rules={[
                        { required: true, message: 'Please input your password!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        })]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </section>
    )
}

export default Activate;
