import Login from "./Auth/Login";
import { Route, Redirect } from 'react-router-dom';
import Main from "./Main/Main";
import {isUserAuthenticated} from "./services/auth.service";
import Activate from "./Auth/Activate";

function App() {
    return (
        <div>
            <Route path='/' exact={true} render={() => isUserAuthenticated() ?  <Main/> : <Login />}/>
            <Route path='/dashboard' render={() => isUserAuthenticated() ?  <Main/> : <Redirect to={'/'}/>}/>
            <Route path='/activate' render={() => isUserAuthenticated() ? <Main/> : <Activate/>}/>
        </div>
    )
}

export default App;
