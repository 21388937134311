import './Metrics.css';

function Metrics({ metrics: { mostPopularAction, totalInvitesOpenedCount: active, totalInvitesSentCount: sent } }) {
  return (
    <>
      <div className="metrics-title">Metrics</div>
      <div className="metrics-container">
        <div className="metrics-item">
          <div>Invitations sent</div>
          {sent}
        </div>
        <div className="metrics-item">
          <div>Invitations active</div>
          {active}
        </div>
        <div className="metrics-item">
          <div>Most Popular Action</div>
          {mostPopularAction}
        </div>
      </div>
    </>
  );
}

export default Metrics;
