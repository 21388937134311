import { useState } from 'react';
import axios from '../services/request';
import { Modal, Button } from 'antd';

import './CreateDemo.css';
import {api} from "../config";
import {getRequestHeaders} from "../services/auth.service";

function CreateDemo({ visible, setVisible }) {
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [companyName, setCompanyName] = useState();
  const requestUrl = `${api}/demo/invitation`;

  const sendDemoInvite = () => {
    const params = `email=${email}&firstName=${firstName}&lastName=${lastName}&companyName=${companyName}`;
    const options = {
      headers: getRequestHeaders(),
    };

    axios.post(requestUrl, params, options).then(() => {
      setVisible(false);
    }).catch((e) => {
      alert(`Smth went wrong` + e);
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      width="364px"
      height="500px"
      className="modal"
      title="Create Demo Dashboard"
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <h2 className="createDemoModal-text">
        Fill in the form to invite a potential client. Please be aware that demo dashboard will be deleted on sunday.
      </h2>
      <input
        className="createDemoModal-input"
        onChange={(e) => setFirstName(e.target.value)}
        placeholder="First name"
      ></input>
      <input
        className="createDemoModal-input"
        onChange={(e) => setLastName(e.target.value)}
        placeholder="Last name"
      ></input>
      <input
        className="createDemoModal-input"
        onChange={(e) => setCompanyName(e.target.value)}
        placeholder="Company name"
      ></input>
      <input
        className="createDemoModal-input"
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Company email"
      ></input>
      <Button className="createDemoModal-button" onClick={sendDemoInvite}>
        Send invitation
      </Button>
    </Modal>
  );
}

export default CreateDemo;
