const ACCESSS_TOKEN = 'wvs-access-token';

function isUserAuthenticated() {
    return !!localStorage.getItem(ACCESSS_TOKEN);
}

function logout() {
    removeToken(ACCESSS_TOKEN);
}

function removeToken(tokenName) {
    localStorage.removeItem(tokenName);
}

function setToken(token) {
    localStorage.setItem(ACCESSS_TOKEN, token);
}

function getToken() {
    return localStorage.getItem(ACCESSS_TOKEN);
}

function getAuthorizationHeader() {
    const bearerToken = `Bearer ${getToken()}`;
    return { 'Authorization':  bearerToken };
}

function getRequestHeaders(isAuthenticated=true) {
    const baseHeaders = { 'Content-Type': 'application/x-www-form-urlencoded' };
    let headers = baseHeaders;

    if(isAuthenticated) {
        headers = Object.assign({} , baseHeaders, getAuthorizationHeader());
    }

    return headers;
}

module.exports = {
    setToken,
    isUserAuthenticated,
    logout,
    getRequestHeaders,
}
